@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@300;400;500;600;700;800&display=swap");

.dashboard_card {
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.account_balance_title {
  background: linear-gradient(30deg, #239ed8, #1c79be);
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
}

.account_balance_title a {
  color: #fff;
}

.account_b_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  margin-bottom: 12px;
}

.account_b_box.account_b_box-dark {
  border-bottom: 1px solid #303030;
}

.account_b_box p {
  margin: 0;
  margin-bottom: 7px;
}

.dash_account {
  margin-top: 20px;
}

.test_sales_card {
  background: #fff3e4;
  padding: 5px;
}

.dashboard-item {
  background: #f3f6f9;
  padding: 20px;
  border-radius: 5px;
}

.dashboard-item-dark {
  background: #141414;
  padding: 20px;
  border-radius: 5px;
  color: white;
}

.mp-0 {
  margin: 0 !important;
  padding: 0 !important;
}

.poppins {
  font-family: Poppins;
}

.block {
  display: block;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.dashboard-invoices-chart {
  padding: 15px;
}

.p-15 {
  padding: 15px;
}

.dash_expense {
  margin-top: 15px;
}

.dash_expense_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dash_client {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.dash_client.dash_client-dark {
  border-bottom: 1px solid #303030;
}

.client_dash_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client_dash_box p {
  margin: 0;
  margin-bottom: 5px;
}

.dashboard-box-shape {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 5px;
  /* border: 1px solid #b3a398; */
}

.dashboard-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  background: linear-gradient(30deg, #239ed8, #1c79be);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.blinking {
  animation: blink 1s infinite;
  color: red;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
