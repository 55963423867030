.vendor-details p {
    margin: 5px 0;
}

svg.recharts-surface {
    position: 'relative !importatnt';
    top: 5px;
    /* margin-top: -100px; */
}

path.recharts-curve.recharts-pie-label-line {
    display: none;
}