.air-ticket-view-container .taxes {
  margin-top: 10px;
}
.air-ticket-view-container .title {
  margin: 10px 0;
  color: #222222;
}

.air-ticket-view-container .col-border {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 5px 0;
  font-size: 11px;
}
.air-ticket-view-container .col-border:first-child {
  border-top: 1px solid lightgray;
}
.air-ticket-view-container .col-border:nth-child(2) {
  border-top: 1px solid lightgray;
}
.air-ticket-view-container .col-border:nth-child(3) {
  border-top: 1px solid lightgray;
}
.air-ticket-view-container .col-border:nth-child(4) {
  border-top: 1px solid lightgray;
}

.air-ticket-view-container .col-border span {
  padding: 5px;
}

.air-ticket-view-container .col-border span:first-child {
  background-color: #f1f1f1;
  font-weight: 600;
}
.air-ticket-view-container .col-border span:nth-child(2) {
  padding: 5px 15px;
}
