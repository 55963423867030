.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  padding: 20px;
  max-width: 400px;
}

.profile-image {
  width: 120px;
  padding: 2px;
  box-shadow: #9292920d 0px 0px 0px 1px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-username {
  margin: 0;
  font-size: 24px;
  color: dodgerblue;
}

.profile-name {
  margin: 5px 0 20px;
  font-size: 16px;
  color: #586069;
}

.profile-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}
