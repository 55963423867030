:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --collection: #90d60f;
  --discount: #da3fd2;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #18b4e9;
  --secondary: #6c757d;
  --success: #2a362d;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dde0e2;
  --light: #f8f9fa;
  --dark: #343a40;
  --volcano: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
}

.nowrap {
  white-space: nowrap;
}

.color-sales {
  color: var(--primary);
}

.color-purchase {
  color: var(--orange);
}

.color-profit {
  color: var(--green);
}

.color-loss {
  color: var(--red);
}

.color-due {
  color: var(--red);
}

.color-discount {
  color: var(--discount);
}

.color-collection {
  color: var(--collection);
}
.color-warning {
  color: var(--warning);
}
.color-info {
  color: var(--info);
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.gift-sent {
  background: rgb(208, 241, 208);
}
.gift-not-sent {
  background: rgb(245, 209, 209);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-custom-upload.ant-custom-upload-select {
  width: 250px !important;
  height: 200px !important;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #ffffff0a;
  border: 1px dashed #424242;
  border-radius: 20px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.ant-custom-upload-list.ant-custom-upload-list-picture-card .ant-custom-upload-list-item-container {
  display: inline-block;
  width: 250px !important;
  height: 200px !important;
  margin-block: 0 8px;
  margin-inline: 0 8px;
  vertical-align: top;
}

.over_all_header-header {
  padding: 8px 16px !important;
  padding-right: 72px !important;
}

/* CUSTOM CSS @test purpose */

.bg-primary {
  background-color: #18b4e9;
}

.text-primary {
  color: #18b4e9;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.text-blue {
  color: blue;
}

.tt-uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.border {
  background-color: #7a7a7a1a;
  border-radius: 7px;
}

.p-5 {
  padding: 10px;
}

.p-10 {
  padding: 20px;
}

.mb-5 {
  margin-bottom: 10px;
}

.my-10 {
  margin: 20px 0;
}

.my-20 {
  margin: 40px 0;
}

.py-20 {
  padding: 20px 0;
}

.mt-5 {
  margin-top: 10px;
}

.mt-10 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.p-25 {
  padding: 25px;
}

.p-20 {
  padding: 20px;
}

.p-15 {
  padding: 15px;
}

.uppercase {
  text-transform: uppercase;
}

.secondary-color {
  color: lightgray;
}

.text-white {
  color: #fff;
}

.resWidht {
  width: 400px;
}

.singeTableRow {
  background-color: rgba(255, 99, 71, 0.089) !important;
}

.white {
  background-color: #fafafa;
}

.refund-row-style {
  background-color: rgba(255, 99, 71, 0.089) !important;
}

.void_inv-row-style {
  background-color: #9aff4727 !important;
}

.reissued-row-style {
  background-color: rgba(92, 255, 71, 0.089) !important;
}

.overall-row-style {
  background-color: rgba(71, 105, 255, 0.089) !important;
}

.record-edit-active {
  background-color: rgba(6, 110, 190, 0.432) !important;
}

.deleted-edit-active {
  background-color: #ff80004d !important;
}

.text-center {
  text-align: center;
}

.invoiceBillingTable .invoiceBillingTd > td {
  padding: 5px !important;
  font-size: 11px !important;
}

.invoiceBillingTable .ant-table-thead .ant-table-cell {
  padding: 5px 7px !important;
  font-size: 11px !important;
  /* border-bottom: 1px solid lightgray; */
}

.normal-table .normal-table-row > td {
  padding: 5px !important;
  font-size: 11px !important;
}

.normal-table .ant-table-thead .ant-table-cell {
  padding: 1px 7px !important;
  font-size: 12px !important;
  /* border-bottom: 1px solid lightgray; */
}

.print-table .print-table-row > td {
  padding: 5px !important;
  font-size: 10px !important;
}

.print-table .ant-table-thead .ant-table-cell {
  padding: 3px !important;
  font-size: 11px !important;
}

.print-table th.ant-table-cell {
  border: '1px solid blue' !important;
}

/* common antd table */

.ant-table-tbody > tr > td {
  font-size: 13px !important;
}

/* tr:nth-child(odd) {
  background: #7e7e7e27;
} */

.ant-table-thead > tr > th {
  padding: 10px !important;
  background-color: #174a571f !important;
}

.ant-table-wrapper tfoot > tr > td {
  padding: 2px !important;
  text-align: center !important;
}

.table-column {
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-size: 13px;
}

.table-column-title {
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-size: 13px;
  padding: 0px !important;
  margin: 0px !important;
}

.changeFont {
  /* font-family: 'Source Sans Pro', sans-serif !important; */
  size: 12px;
}

/* custom-styles.css */
/* :where(.css-dev-only-do-not-override-1pvtuze).ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
:where(.css-dev-only-do-not-override-1pvtuze).ant-descriptions.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 4px 16px;
} */

.changeFontBlock {
  /* font-family: 'Source Sans Pro', sans-serif !important; */
  display: block;
  size: 13px;
}

/* Changing page color to white while print */

@media print {
  * {
    color: black !important;
  }
  @page {
    margin: 0px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  ul.ant-pagination.ant-table-pagination {
    display: none;
  }

  /* 
  .page-number {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
    color: #000;
  } */
}

/* end print */

.ant-switch .ant-switch-handle {
  width: 20px;
  height: 20px;
  inset-inline-start: 0;
  padding: 2px;
}

/* Sidebar scrollbar design customize */
.siderDesign {
  overflow-y: scroll !important;
  position: relative;
}

/* Chrome */
.siderDesign::-webkit-scrollbar {
  position: absolute !important;
  right: 0 !important;
  width: 6px;
}

/* Styles for the scroll bar thumb (the draggable portion) */
.siderDesign::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 6px;
  position: absolute;
}

.siderDesign:hover::-webkit-scrollbar-thumb {
  background-color: #b8b5b5;
  border-radius: 5px;
  position: absolute;
}

/* Styles for the scroll bar track (the area behind the thumb) */
.siderDesign::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
  position: absolute;
}

/* For Firefox */
.siderDesign {
  scrollbar-width: thin;
  scrollbar-color: #b8b5b5 #f1f1f1;
}

.siderDesign::-moz-scrollbar-thumb {
  background-color: #b8b5b5;
  border-radius: 5px;
}

.siderDesign::-moz-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.highlight_row {
  animation: blinkBackground 1s 5;
  background-color: rgba(37, 255, 74, 0.089);
}

@keyframes blinkBackground {
  0% {
    background-color: rgba(37, 255, 74, 0.089);
  }

  50% {
    background-color: transparent;
  }

  100% {
    background-color: rgba(37, 255, 74, 0.089);
  }
}

.new-label {
  position: relative;
  padding: 1px 5px;
  border-radius: 5px;
  background-color: lightseagreen;
  color: white;
}

.rowColor1 {
  background-color: #fafafa;
}

.rowColor2 {
  background-color: #e9e4e4;
}

.icon_hover:hover {
  filter: blur(0.8px);
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.d-flex {
  display: flex;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.justify-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.ql-size-large {
  font-size: 25px;
}

.ql-size-small {
  font-size: 14px;
}

.ql-size-medium {
  font-size: 18px;
}

.ql-size-extra-small {
  font-size: 10px;
}

.hover_effect {
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

/* WIDTH */
.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

/* DISPLAY STYLES */
.d-block {
  display: block;
}

.font-bold {
  font-weight: bold;
}

.font-warning {
  color: #f50;
}

.font-success {
  color: #87d068;
}

.report_total_footer {
  color: green;
  font-weight: 700;
  text-align: right;
  margin-right: 10px;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 0px 0px !important;
}

.fs-12 {
  font-size: 12px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Dashboard.css */

/* Style for the table container */
#widgetsTable {
  width: 100%;
}

/* Border and padding for table cells */
#widgetsTable .ant-table-cell {
  padding: 12px 8px;
}

/* Styling for the table header */
#widgetsTable .ant-table-thead > tr > th {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

/* Styling for table rows */
#widgetsTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #dddddd;
}

/* Alternating row colors */
#widgetsTable .ant-table-tbody > tr:nth-child(even) {
  background-color: #f3f3f3;
}

/* Last row bottom border */
#widgetsTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 2px solid #009879;
}

/* Hover effect for rows */
#widgetsTable .ant-table-tbody > tr:hover > td {
  background-color: #d4f1f4;
}

/* Styling for links */
#widgetsTable a {
  color: #009879;
  text-decoration: none;
}

#widgetsTable a:hover {
  text-decoration: underline;
}

/* Aligning text to the right for specific columns */
#widgetsTable .ant-table-cell-right {
  text-align: right;
}

.test {
  background-color: rgba(255, 0, 0, 0.041);
}

.IATA_BILL {
  background-color: #e6e6e66c;
}

@media print {
  .no-print {
    display: none;
  }

  tfoot {
    display: table-row-group;
  }

  .print-table {
    border-collapse: collapse;
    width: 100%;
  }

  .print-table,
  .print-table th,
  .print-table td {
    border: 1px solid gray;
  }

  .print-table th,
  .print-table td {
    border: 1px solid gray;
  }
}

.myRadio-item {
  margin: 0 !important;
}

.myCol {
  position: 'absolute';
  top: -5px;
  margin-bottom: -10px;
  left: 10px;
}

.custom_radio-wrapper {
  margin-inline-end: 0px !important;
}

.custom_radio-wrapper span.custom_radio + * {
  padding-inline-start: 5px;
  /* padding-inline-end: 8px; */
}

.sticky-column {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 75vh;
}

.bf-row .ant-table-cell:not(:last-child) {
  border-left: none !important;
  border-right: none !important;
}

.decoration-no-padding.decoration-no-padding-bordered.decoration-no-padding-small
  .decoration-no-padding-row
  > .decoration-no-padding-item-label,
.decoration-no-padding.decoration-no-padding-bordered.decoration-no-padding-small
  .decoration-no-padding-row
  > .decoration-no-padding-item-content {
  padding: 2px 16px;
}

.custom-billing-table-title {
  padding: 1px !important;
  font-weight: 500;
}

th.custom-billing-table-cell {
  padding: 2px !important;
  background-color: #e3e9eb !important;
}

.button-checkbox-wrapper {
  background: black;
  padding: 0px 4px;
  border-radius: 3px;
  color: white;
}

tfoot.ant-table-summary {
  background: #3f909b27 !important;
  height: 30px;
}

.summary-background {
  background: #e2eef0 !important;
}

:where(.css-dev-only-do-not-override-di504q).ant-badge.ant-badge-status .ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 13px;
  height: 13px;
  vertical-align: middle;
  border-radius: 50%;
}

:where(.css-di504q).ant-badge.ant-badge-status .ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 13px !important;
  height: 13px !important;
  vertical-align: middle;
  border-radius: 50%;
}
.hide-table .ant-table-tbody {
  display: none !important;
}

.over_all_profit_loss .ant-table-tbody > tr:nth-child(even) {
  background: #daeaf0 !important;
}
.over_all_profit_loss .ant-table-cell-row-hover {
  background: #dddada !important;
}
