.report .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  background: linear-gradient(30deg, #239ed8, #1c79be);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}

.show_more_collapse-header {
  padding: 10px 0px !important;
}

.show_more_collapse-content-box {
  padding: 0px 0px !important;
}

/* 
 .report .title img {
   width: 31px;
   object-fit: cover;
 } */

.report .title h3 {
  font-size: 15px;
  font-weight: 700;
  margin: 0;
  margin-right: 12px;
}

.report_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}

.report_box.report_box-dark {
  border-bottom: 1px solid #303030;
}

.report_box.last_box {
  border-bottom: 0;
}

.report_box p {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
  color: #404040;
  margin: 7px 0;
}

.report_box.report_box-dark p {
  color: rgba(255, 255, 255, 0.85);
}

/* ------------------------------------------------ */

.dash_vendor_details .title {
  background: linear-gradient(30deg, #239ed8, #1c79be);
  text-align: center;
  color: #fff;
}

.dash_vendor_details .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  border-bottom: 1px solid #e6e6e6;
}

.dash_vendor_details .box.dark-box {
  border-bottom: 1px solid #303030;
}

.vendor_details-title {
  margin: 0;
  margin-bottom: 10px;
  color: #1e88c8;
}

.dash_vendor_title {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
}

.dash_vendor_details .box p {
  margin: 0;
  padding-bottom: 4px;
}

.dash_vendor_details .box.last_box {
  border-bottom: 0;
}

/* -------------------------------- iata summery */
h3.dash_summery_title {
  background: linear-gradient(30deg, #239ed8, #1c79be);
  color: #fff;
  padding: 1px 9px;
}

.billing {
  margin-bottom: 15px;
  font-size: 15px;
}

/* ------------------------- vendor details */
.dashboard_details_vendor {
  overflow-y: scroll;
  height: 268px;
}

/* ----------------------------------------- */
.billing_details {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: minmax(70px, auto);
  /* margin-bottom: 20px; */
}

.billing_details .box {
  width: 100%;
}

.billing_details .box p:first-child {
  border-bottom: 2px solid #c9c9c9;
  padding-bottom: 6px;
  margin: 0;
}

.billing_details .box.dark-box p:first-child {
  border-bottom: 1px solid #303030;
}

.billing_details .box p:last-child {
  margin: 0;
  margin-top: 8px;
}

.billing_details .box.dark-box {
  border-bottom: 1px solid #303030;
  color: white;
}

.bspBilling {
  margin-bottom: 10px;
}
