.profit-loss-table {
    margin: 20px auto;
    padding: 20px;
    width: 80%;
    max-width: 800px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    background-color: #fff;
}

.profit-loss-table h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.profit-loss-table table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.profit-loss-table th,
.profit-loss-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.profit-loss-table th {
    background-color: #f4f4f4;
}

.profit-loss-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.profit-loss-table tbody tr:hover {
    background-color: #f1f1f1;
}

.profit-loss-table tbody tr th {
    font-weight: bold;
    text-align: right;
}

.profit-loss-table tbody tr th[colspan="2"] {
    text-align: left;
    background-color: #f9f9f9;
}